import React, { useContext, useReducer } from "react";
import { Action, ProductState } from "./reducer";

interface ProviderProps {
  initialState: ProductState;
  reducer: (state: ProductState, action: Action) => ProductState;
}

const Store = React.createContext<Partial<[ProductState, React.Dispatch<Action>]>>([]);
Store.displayName = "ProductStore";

export const useProductStore = () => useContext(Store)!;

export const ProductStoreProvider: React.FC<ProviderProps> = ({
  children,
  initialState,
  reducer,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Store.Provider value={[state, dispatch]}>{children}</Store.Provider>;
};
