import React from "react";
import { range } from "lodash";
import { useI18next } from "gatsby-plugin-react-i18next";

interface AgeSelectProps {
  value?: number;
  isLoading?: boolean;
  onChange?: (value: number) => void;
}

const AgeSelect: React.FC<AgeSelectProps> = (props) => {
  const { t } = useI18next();
  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    if (props.onChange) {
      props.onChange(event.target.selectedIndex);
    }
  };

  return (
    <div className="dropdown dropdown--small">
      <select onChange={handleChange} value={props.value ?? 0} disabled={props.isLoading}>
        {range(0, 18).map((age) => (
          <option key={age} value={age}>
            {t("X_YEAR", { age })}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AgeSelect;
