import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";

interface AmountInputProps {
  label: string;
  value: number;
  isLoading?: boolean;
  min?: number;
  onChange?: (value: number) => void;
}

const AmountInput: React.FC<AmountInputProps> = (props) => {
  const { t } = useI18next();
  const handleIncreaseClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (props.onChange) {
      props.onChange(props.value + 1);
    }
  };

  const handleDecreaseClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (props.onChange) {
      props.onChange(Math.max(props.value - 1, props.min ?? 0));
    }
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (props.onChange) {
      const value =
        event.target.valueAsNumber < (props.min ?? 0) ? props.min ?? 0 : event.target.valueAsNumber;
      props.onChange(value);
    }
  };

  return (
    <div className="decrement-increment">
      <label className="decrement-increment__label">{props.label}</label>
      <div className="decrement-increment__ui">
        <button
          type="button"
          className="button button--decrement"
          title={t("DECREASE")}
          disabled={props.isLoading}
          onClick={handleDecreaseClick}
        ></button>
        <input
          type="number"
          min={props.min}
          value={props.value}
          className="decrement-increment__input"
          onChange={handleInputChange}
        />
        <button
          type="button"
          className="button button--increment"
          title={t("TO_ADD")}
          disabled={props.isLoading}
          onClick={handleIncreaseClick}
        ></button>
      </div>
    </div>
  );
};

export default AmountInput;
