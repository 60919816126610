import { TideClientConfig, search, details } from "@qite/tide-client";
import {
  Image,
  Maybe,
  TideItem,
  TideItemForCountry,
  TideItemForHotel,
  TideItemForRegion,
  TideItemForRoundtrip,
  TideProduct,
} from "../../../types";
import { compact, get, isNil, uniqBy } from "lodash";
import { differenceInDays, format, parse } from "date-fns";

import { Room } from "../qsm/room-picker";
import { generateParameterizedProductPath } from "../../../utils";
import {
  BookingPackage,
  BookingPackageDestination,
  BookingPackageDetailsRequest,
  BookingPackageItem,
  BookingPackagePax,
  BookingPackageRequest,
  BookingPackageRequestRoom,
  BookingPackageSearchRequest,
  TideResponse,
} from "@qite/tide-client/build/types";

export interface SearchQuery {
  country?: number;
  region?: number;
  searchGlobal?: boolean;
  rooms?: Room[];
  from?: string;
  to?: string;
  regimeCodes?: string[];
  minPrice?: number;
  maxPrice?: number;
}

export interface SearchResult {
  key: string;
  from: Date;
  to: Date;
  productType: number;
  title?: string;
  subtitle?: string;
  image?: Image;
  badges?: string[];
  rating?: number;
  price?: number;
  url?: string;
  uspIds: string[];
  themeIds: string[];
  hasFlight: boolean;
  hasTransfer: boolean;
  onRequest: boolean;
  isNotAvailable: boolean;
  minStay?: string;
  countryName?: string;
}

export const searchProducts = async (
  query: SearchQuery,
  hotels: TideItemForHotel[],
  roundtrips: TideItemForRoundtrip[],
  t: Function,
  language: string,
  token: string | null
): Promise<SearchResult[]> => {
  const host = process.env.TIDE_HOST;
  const apiKey = process.env.API_KEY;

  if (
    !host ||
    !apiKey ||
    !(query.country || query.region || query.searchGlobal) ||
    !query.from ||
    !query.to ||
    !query.rooms
  ) {
    return Promise.reject();
  }
  const config: TideClientConfig = {
    host,
    apiKey,
    token: token ?? undefined,
  };
  const searchRequest: BookingPackageRequest<BookingPackageSearchRequest> = getPackageSearchRequest(
    query.from,
    query.to,
    query.rooms,
    query.country,
    query.region,
    query.regimeCodes,
    query.minPrice,
    query.maxPrice
  );

  try {
    var countryOrRegionHotels: TideItemForHotel[] = [];
    var countryOrRegionRoundtrips: TideItemForRoundtrip[] = [];

    if (query.region) {
      countryOrRegionHotels = hotels.filter(
        (x) =>
          x.parentItem?.templateName === "Region" &&
          (x.parentItem as TideItemForRegion)?.content?.general?.region?.tideId === query.region
      );
      countryOrRegionRoundtrips = roundtrips.filter(
        (x) =>
          x.parentItem?.templateName === "Region" &&
          (x.parentItem as TideItemForRegion)?.content?.general?.region?.tideId === query.region
      );
    } else if (query.country) {
      countryOrRegionHotels = hotels.filter(
        (x) =>
          (x.parentItem?.templateName === "Country" &&
            (x.parentItem as TideItemForCountry)?.content?.general?.country?.tideId ===
              query.country) ||
          (x.parentItem?.parentItem?.templateName === "Country" &&
            (x.parentItem?.parentItem as TideItemForCountry)?.content?.general?.country?.tideId ===
              query.country)
      );
      countryOrRegionRoundtrips = roundtrips.filter(
        (x) =>
          (x.parentItem?.templateName === "Country" &&
            (x.parentItem as TideItemForCountry)?.content?.general?.country?.tideId ===
              query.country) ||
          (x.parentItem?.parentItem?.templateName === "Country" &&
            (x.parentItem?.parentItem as TideItemForCountry)?.content?.general?.country?.tideId ===
              query.country)
      );
    } else {
      countryOrRegionHotels = hotels;
      countryOrRegionRoundtrips = roundtrips;
    }

    const searchResults = await search(config, searchRequest).then((packageSearchResults) => {
      if (!query.from || !query.to || !query.rooms) {
        return Promise.reject();
      }
      return createSearchResults(
        countryOrRegionHotels,
        countryOrRegionRoundtrips,
        packageSearchResults,
        query.rooms,
        query.from,
        query.to,
        t,
        language
      );
    });

    return uniqBy(searchResults, (x) => x.key);
  } catch (e) {
    console.error(e);
    return Promise.reject();
  }
};

export const getPackageSearchRequest = (
  from: string,
  to: string,
  rooms: Room[],
  country?: number,
  region?: number,
  regimeCodes?: string[],
  minPrice?: number,
  maxPrice?: number
): BookingPackageRequest<BookingPackageSearchRequest> => {
  // Determine destination
  let destinationId: number | undefined = undefined;
  let destinationIsCountry = false;
  let destinationIsRegion = false;

  if (country !== undefined) {
    destinationId = country;
    destinationIsCountry = true;
  } else if (region !== undefined) {
    destinationId = region;
    destinationIsRegion = true;
  }

  // Request
  return {
    officeId: 1,
    payload: {
      catalogueIds: [1],
      searchType: 0,
      destination: {
        id: Number(destinationId),
        isCountry: destinationIsCountry,
        isRegion: destinationIsRegion,
        isOord: false,
        isLocation: false,
      } as BookingPackageDestination,
      rooms: getRequestRooms(rooms),
      fromDate: from,
      toDate: to,
      includeFlights: true,
      regimeCodes: regimeCodes,
      minPrice: minPrice,
      maxPrice: maxPrice,
    } as BookingPackageSearchRequest,
  };
};

export const getPackageDetails = (
  rooms: Room[],
  from: string,
  to: string,
  productCode?: Maybe<string>,
  signal?: AbortSignal,
  languageCode?: string
): Promise<TideResponse<BookingPackage>> => {
  const host = process.env.TIDE_HOST;
  const apiKey = process.env.API_KEY;

  if (!host || !apiKey || !productCode || !from || !to || !rooms) {
    return Promise.reject();
  }

  const config: TideClientConfig = {
    host,
    apiKey,
  };

  const request = {
    officeId: 1,
    payload: {
      searchType: 0,
      catalogueId: 1,
      productCode: productCode,
      fromDate: from,
      toDate: to,
      rooms: getRequestRooms(rooms),
      includeFlights: true,
    },
  } as BookingPackageRequest<BookingPackageDetailsRequest>;

  return details(config, request, signal, languageCode);
};

const createSearchResults = (
  hotels: TideItemForHotel[],
  roundtrips: TideItemForRoundtrip[],
  packageItems: BookingPackageItem[],
  rooms: Room[],
  from: string,
  to: string,
  t: Function,
  language: string
): SearchResult[] => {
  return compact([
    ...hotels.map((hotel) => buildSearchResult(t, language, packageItems, rooms, from, to, hotel)),
    ...roundtrips.map((roundTrip) =>
      buildSearchResult(t, language, packageItems, rooms, from, to, undefined, roundTrip)
    ),
  ]);
};

export const getParentItemWithTypename = <T extends TideItem>(
  typename: string,
  item?: TideItem
): T | undefined => {
  if (isNil(item) || isNil(item.parentItem)) {
    return undefined;
  }

  if (get(item.parentItem, "__typename") !== typename) {
    return getParentItemWithTypename("TideItemForCountry", item.parentItem);
  }

  return item.parentItem as T;
};

const buildSearchResult = (
  t: Function,
  language: string,
  items: BookingPackageItem[],
  rooms: Room[],
  from: string,
  to: string,
  hotel?: TideItemForHotel,
  roundtrip?: TideItemForRoundtrip
) => {
  const product = hotel ? hotel?.content?.general?.product : roundtrip?.content?.general?.product;
  const isRoundTrip = roundtrip && !hotel;
  let minNights = roundtrip?.content?.general?.duration;
  const minDurationType = roundtrip?.content?.general?.product?.minDurationType;
  const minDuration = roundtrip?.content?.general?.product?.minDuration;

  if (minDurationType && minDuration) {
    if (minDurationType === 1) {
      minNights = minDuration - 1;
    } else if (minDurationType === 2) {
      minNights = minDuration;
    }
  }
  const item = items.find((x) => x.code === product?.code);

  const queryDuration = differenceInDays(
    new Date(to),
    item && item.outwardFlightEndDate
      ? new Date(
          format(
            parse(item.outwardFlightEndDate, "yyyy-MM-dd'T'HH:mm:ss'Z'", new Date()),
            "yyyy-MM-dd"
          )
        )
      : new Date(from)
  );
  //const queryDurationOld = differenceInDays(new Date(to), new Date(from));

  if (!product?.code) {
    return undefined;
  }

  let onRequest = isRoundTrip
    ? minNights! < queryDuration
      ? true
      : false
    : product?.allotmentType == 1 || false;
  let isNotAvailable = !item
    ? true
    : isRoundTrip
    ? minNights! != queryDuration
      ? true
      : false
    : false;

  if (item?.price && item.price == item.flightPrice) {
    isNotAvailable = true;
  }

  let countryName = item?.countryName;
  if (isRoundTrip) {
    countryName =
      (getParentItemWithTypename("TideItemForCountry", roundtrip) as TideItemForCountry).content
        ?.general?.title ?? item?.countryName;
    if (!minNights) {
      return undefined;
    }
  } else {
    countryName =
      (getParentItemWithTypename("TideItemForCountry", hotel) as TideItemForCountry).content
        ?.general?.title ?? item?.countryName;
  }

  const searchResult: SearchResult = {
    key: product?.code,
    from: new Date(from),
    to: new Date(to),
    productType: item?.type ?? (hotel ? 3 : 1),
    title: hotel?.content?.general?.title ?? roundtrip?.content?.general?.title ?? undefined,
    subtitle:
      isRoundTrip && isNotAvailable
        ? "Min. " + getMinStay(t, hotel, roundtrip)
        : getSubtitle(t, language, item, product),
    image:
      hotel?.content?.general?.thumbnailPicture ??
      roundtrip?.content?.general?.thumbnailPicture ??
      undefined,
    rating: hotel?.content?.general?.stars ?? undefined,
    price: item?.price && item.price != item.flightPrice ? item.price : 0,
    url: generateParameterizedProductPath(
      hotel || roundtrip,
      rooms,
      new Date(from),
      new Date(to),
      item?.accommodationCode,
      item?.regimeCode
    ),
    uspIds: compact(hotel?.content?.general?.usps?.map((usp) => usp?.id)),
    themeIds: compact(hotel?.content?.general?.themes?.map((theme) => theme?.id)),
    hasFlight: item?.includedServiceTypes?.some((x) => x === 7) || false,
    hasTransfer: item?.includedServiceTypes?.some((x) => x === 13) || false,
    onRequest: onRequest,
    isNotAvailable: isNotAvailable,
    minStay: getMinStay(t, hotel, roundtrip),
    countryName: countryName,
  };
  return searchResult;
};

export const getMinStay = (
  t: Function,
  hotel?: TideItemForHotel,
  roundtrip?: TideItemForRoundtrip
) => {
  if (hotel?.content?.general?.estimatedStay) return hotel.content.general.estimatedStay;
  if (roundtrip?.content?.general?.estimatedStay) return roundtrip.content.general.estimatedStay;

  const product = hotel ? hotel?.content?.general?.product : roundtrip?.content?.general?.product;
  if (!product) return undefined;

  switch (product.minDurationType) {
    case 0:
      return `${product.minDuration} ${t("HOUR")}`;
    case 1:
      return `${product.minDuration} ${product.minDuration == 1 ? t("DAY") : t("DAYS")}`;
    case 2:
      return `${product.minDuration} ${product.minDuration == 1 ? t("NIGHT") : t("NIGHTS")}`;
  }
};

const removeTime = (date = new Date()) => {
  return new Date(date.toDateString());
};

const getSubtitle = (
  t: Function,
  language: string,
  item?: BookingPackageItem,
  product?: TideProduct
) => {
  const from = item ? removeTime(new Date(item.fromDate)) : null;
  const to = item ? removeTime(new Date(item.toDate)) : null;

  if (!from || !to) {
    return undefined;
  }

  const days = differenceInDays(to, from);
  const regime = product?.regimes?.find((x) => x?.code == item?.regimeCode);
  const regimeName =
    regime?.localizedNames?.find((x) => x?.languageCode == language)?.value ?? regime?.name;

  return compact([
    format(from, "dd/MM/yyy"),
    days && `, ${t("X_NIGHTS", { numberOfNights: days })}`,
    regimeName && `, ${regimeName}`,
  ]).join("");
};

export const getRequestRooms = (rooms: Room[]) => {
  let requestRooms: BookingPackageRequestRoom[] = [];
  rooms.forEach((room, i) => {
    let requestRoom = {
      index: i,
      pax: [],
    } as BookingPackageRequestRoom;

    for (var a = 0; a < room.adults; a++) {
      requestRoom.pax.push({
        age: 30,
      } as BookingPackagePax);
    }

    for (var c = 0; c < room.children; c++) {
      requestRoom.pax.push({
        age: room.childAges[c],
      } as BookingPackagePax);
    }
    requestRooms.push(requestRoom);
  });
  return requestRooms;
};
