import React, { useEffect, useState } from "react";
import { format, isBefore, startOfDay } from "date-fns";

import DateRangePicker from "../../../components/date-range-picker";
import Icon from "../../../components/icon";
import { buildClassName } from "../../../utils";
import { usePopper } from "react-popper";
import { useI18next } from "gatsby-plugin-react-i18next";

export interface DateRange {
  fromDate?: Date;
  toDate?: Date;
}

interface DatePickerProps {
  value?: DateRange;
  panelActive?: boolean;
  duration?: number;
  isProductIntro?: boolean;
  onPanelToggle?: (state: boolean) => void;
  onChange?: (dateRange: DateRange) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({
  value,
  panelActive,
  duration,
  isProductIntro,
  onPanelToggle,
  onChange,
}) => {
  const { t } = useI18next();
  const mql = typeof window !== "undefined" ? window.matchMedia("(min-width: 1200px)") : undefined;

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 20],
        },
      },
      {
        name: "preventOverflow",
        options: {
          padding: 40,
        },
      },
      { name: "arrow", options: { element: arrowElement } },
    ],
  });

  const handleDocumentClick: EventListener = (event) => {
    if (referenceElement && !referenceElement.contains(event.target as Node)) {
      if (onPanelToggle) onPanelToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  });

  const handleInputFocus: React.MouseEventHandler<HTMLInputElement> = () => {
    if (onPanelToggle) {
      onPanelToggle(true);
    }
  };

  const handleSelectionChange = (fromDate?: Date, toDate?: Date) => {
    if (onChange) {
      onChange({ fromDate, toDate });

      if (onPanelToggle) onPanelToggle(false);
    }
  };

  return (
    <div
      className="form__group form__group--datepicker form__group--icon"
      ref={setReferenceElement}
    >
      <div className="form__group-input">
        {!isProductIntro && <Icon name="qsm-calendar" />}
        <label className="form__label">{t("DEPARTURE")}</label>
        <input
          type="text"
          readOnly
          value={value?.fromDate ? format(value.fromDate, "dd/MM/yyyy") : ""}
          className="form__input"
          placeholder={t("DEPARTURE_DATE")}
          onClick={handleInputFocus}
        />
      </div>

      <div className="form__group-input">
        <label className="form__label">{t("RETURN")}</label>
        <input
          type="text"
          readOnly
          value={value?.toDate ? format(value.toDate, "dd/MM/yyyy") : ""}
          className="form__input"
          placeholder={t("RETURN_DATE")}
          onClick={handleInputFocus}
        />
      </div>
      <div
        ref={setPopperElement}
        className={buildClassName([
          "qsm__panel",
          "qsm__panel--bordered",
          "qsm__panel--dates-pricing",
          panelActive && "qsm__panel--active",
        ])}
        style={mql?.matches ? styles.popper : undefined}
        {...attributes.popper}
      >
        <DateRangePicker
          fromDate={value?.fromDate}
          toDate={value?.toDate}
          duration={duration}
          disabledDaysFunction={(date) => isBefore(date, startOfDay(new Date()))}
          onSelectionChange={handleSelectionChange}
        />
        {mql?.matches && (
          <div className="qsm__panel-arrow" ref={setArrowElement} style={styles.arrow} />
        )}
      </div>
    </div>
  );
};

export default DatePicker;
