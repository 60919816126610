import React from "react";
import { getDate, isToday } from "date-fns";
import { buildClassName } from "../../utils";

interface CalendarDayProps {
  day: Date;
  isSelected: boolean;
  isDisabled: boolean;
  isOutsideMonth: boolean;
  extraClassNames?: string[];
  onClick: (date: Date) => void;
  onMouseOver: (date: Date) => void;
}

const CalendarDay: React.FC<CalendarDayProps> = ({
  day,
  isSelected,
  isDisabled,
  isOutsideMonth,
  extraClassNames = [],
  children,
  onClick,
  onMouseOver,
}) => {
  const handleClick = () => {
    if (onClick && !isDisabled) {
      onClick(day);
    }
  };

  const handleMouseOver = () => {
    if (onMouseOver) {
      onMouseOver(day);
    }
  };

  const classNames = buildClassName([
    "calendar__day",
    isToday(day) && "calendar__day--today",
    isDisabled && "calendar__day--disabled",
    isOutsideMonth && "calendar__day--outside-month",
    isSelected && "calendar__day--selected",
    ...extraClassNames,
  ]);

  return (
    <div className={classNames} onClick={handleClick} onMouseOver={handleMouseOver}>
      {getDate(day).toString().padStart(2, "0")}
      {children}
    </div>
  );
};

export default CalendarDay;
