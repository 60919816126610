import { BookingPackageFlight } from "@qite/tide-client/build/types";
import { Price } from "../../../components/pricing";

export interface ProductState {
  price?: Price;
  estimatedPrice?: Price;
  hasFlight?: boolean;
  hasTranfer?: boolean;
  isUnavailable?: boolean;
  regimeFlyInIsOpen?: boolean;
  flightsFlyInIsOpen?: boolean;
}

const actionPrefix = "APP/PRODUCT";

export const SET_PRICE = `${actionPrefix}/SET_PRICE`;
export const setPrice = (price?: Price) => ({
  type: SET_PRICE,
  price,
});

export const SET_ESTIMATED_PRICE = `${actionPrefix}/SET_ESTIMATED_PRICE`;
export const setEstimatedPrice = (price?: Price) => ({
  type: SET_ESTIMATED_PRICE,
  price,
});

export const SET_HAS_FLIGHT = `${actionPrefix}/SET_HAS_FLIGHT`;
export const setHasFlight = (hasFlight: boolean) => ({
  type: SET_HAS_FLIGHT,
  hasFlight,
});

export const SET_HAS_TRANSFER = `${actionPrefix}/SET_HAS_TRANSFER`;
export const setHasTransfer = (hasTransfer: boolean) => ({
  type: SET_HAS_TRANSFER,
  hasTransfer,
});

export const SET_IS_UNAVAILABLE = `${actionPrefix}/SET_IS_UNAVAILABLE`;
export const setIsUnavailable = (isUnavailable: boolean) => ({
  type: SET_IS_UNAVAILABLE,
  isUnavailable,
});

export const SET_REGIME_FLYIN_IS_OPEN = `${actionPrefix}/SET_REGIME_FLYIN_IS_OPEN`;
export const setRegimeFlyInIsOpen = (flyInOpen: boolean) => ({
  type: SET_REGIME_FLYIN_IS_OPEN,
  flyInOpen,
});

export const SET_FLIGHTS_FLYIN_IS_OPEN = `${actionPrefix}/SET_FLIGHTS_FLYIN_IS_OPEN`;
export const setFlightsFlyInIsOpen = (flyInOpen: boolean) => ({
  type: SET_FLIGHTS_FLYIN_IS_OPEN,
  flyInOpen,
});

export type Action =
  | ReturnType<typeof setPrice>
  | ReturnType<typeof setEstimatedPrice>
  | ReturnType<typeof setHasFlight>
  | ReturnType<typeof setHasTransfer>
  | ReturnType<typeof setIsUnavailable>
  | ReturnType<typeof setRegimeFlyInIsOpen>
  | ReturnType<typeof setFlightsFlyInIsOpen>;

export const initialProductState: ProductState = {
  price: undefined,
};

const runReducer = (state: ProductState, action: Action): ProductState => {
  switch (action.type) {
    case SET_PRICE:
      return { ...state, price: (action as ReturnType<typeof setPrice>).price };
    case SET_ESTIMATED_PRICE:
      return { ...state, estimatedPrice: (action as ReturnType<typeof setEstimatedPrice>).price };
    case SET_HAS_FLIGHT:
      return { ...state, hasFlight: (action as ReturnType<typeof setHasFlight>).hasFlight };
    case SET_HAS_TRANSFER:
      return { ...state, hasTranfer: (action as ReturnType<typeof setHasTransfer>).hasTransfer };
    case SET_IS_UNAVAILABLE:
      return {
        ...state,
        isUnavailable: (action as ReturnType<typeof setIsUnavailable>).isUnavailable,
      };
    case SET_REGIME_FLYIN_IS_OPEN:
      return {
        ...state,
        regimeFlyInIsOpen: (action as ReturnType<typeof setRegimeFlyInIsOpen>).flyInOpen,
      };
    case SET_FLIGHTS_FLYIN_IS_OPEN:
      return {
        ...state,
        flightsFlyInIsOpen: (action as ReturnType<typeof setFlightsFlyInIsOpen>).flyInOpen,
      };
    default:
      return state;
  }
};

export const productReducer = (
  state: ProductState = initialProductState,
  action: Action
): ProductState => {
  const newState = runReducer(state, action);

  /*
  console.groupCollapsed(action.type);
  console.info("action:", action);
  console.info("new state:", newState);
  console.info("old state:", state);
  console.groupEnd();
  */

  return newState;
};
